const Layouts = () => import('@/layouts/index.vue')

export const GameRouter = {
  path: '/game',
  component: Layouts,
  redirect: '/game/list',
  name: 'Game',
  meta: {
    title: '游戏管理',
    svgIcon: 'menu',
    alwaysShow: true // 将始终显示根菜单
  },
  children: [
    {
      path: 'auto_add_game',
      component: () => import('@/views/game/auto_add_game/index.vue'),
      name: 'AutoAddGame',
      meta: {
        title: '自动添加游戏'
      },
    },
    {
      path: 'auto_add_game/edit',
      component: () => import('@/views/game/auto_add_game/edit/index.vue'),
      name: 'AutoAddGameEdit',
      meta: {
        title: '自动添加游戏 - 编辑',
        hidden: true
      },
    },
    {
      path: 'game_add',
      component: () => import('@/views/game/game_add/index.vue'),
      name: 'GameAdd',
      meta: {
        title: '添加游戏',
        hidden: true
      },
    },
    {
      path: 'list',
      component: () => import('@/views/game/game/list/index.vue'),
      name: 'GameList',
      meta: {
        title: '游戏列表'
      }
    },
    {
      path: 'edit',
      component: () => import('@/views/game/game/edit/index.vue'),
      name: 'GameEdit',
      meta: {
        title: '游戏编辑',
        hidden: true
      }
    },
    {
      path: 'game_tag',
      component: () => import('@/views/game/game_tag/index.vue'),
      name: 'GameTag',
      meta: {
        title: '游戏标签',
      },
    },
    {
      path: 'game_tag/add',
      component: () => import('@/views/game/game_tag/edit/index.vue'),
      name: 'GameTagAdd',
      meta: {
        title: '添加游戏标签',
        hidden: true
      },
    },
    {
      path: 'game_tag/edit',
      component: () => import('@/views/game/game_tag/edit/index.vue'),
      name: 'GameTagEdit',
      meta: {
        title: '编辑游戏标签',
        hidden: true
      },
    },
    {
      path: 'game_tag/adjust',
      component: () => import('@/views/game/game_tag/adjust/index.vue'),
      name: 'GameTagAdjust',
      meta: {
        title: '游戏标签调整',
        hidden: true
      },
    },
    {
      path: 'search_kw',
      component: () => import('@/views/game/search_kw/index.vue'),
      name: 'SearchKW',
      meta: {
        title: 'PC搜索关键词控制',
      },
    },
    {
      path: 'search_kw/edit',
      component: () => import('@/views/game/search_kw/edit/index.vue'),
      name: 'SearchKWEdit',
      meta: {
        title: '编辑搜索词',
        hidden: true
      },
    },
    {
      path: 'search_kw/add',
      component: () => import('@/views/game/search_kw/edit/index.vue'),
      name: 'SearchKWAdd',
      meta: {
        title: '新增搜索词',
        hidden: true
      },
    },
    {
      path: 'pc_game_download',
      component: () => import('@/views/game/pc_game_download/index.vue'),
      name: 'PCGameDownload',
      meta: {
        title: 'PC游戏下载资源',
      },
    },
    {
      path: 'pc_game_download/add',
      component: () => import('@/views/game/pc_game_download/edit/index.vue'),
      name: 'PCGameDownloadAdd',
      meta: {
        title: '添加PC游戏下载资源',
        hidden: true
      },
    },
    {
      path: 'pc_game_download/edit',
      component: () => import('@/views/game/pc_game_download/edit/index.vue'),
      name: 'PCGameDownloadEdit',
      meta: {
        title: '编辑PC游戏下载资源',
        hidden: true
      },
    },
  ]
}
